<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col class="text-center">
        <h2>Contact</h2>
        <address>
          <dl>
            <dt><v-icon>mdi-phone</v-icon></dt>
            <dd><a href="tel:0102803350">010 - 280 33 50</a></dd>
          </dl>
          <dl>
            <dt><v-icon>mdi-email</v-icon></dt>
            <dd>
              <a href="mailto:info@osteos.nl">info@osteos.nl</a>
            </dd>
          </dl>
        </address>
        <v-btn
          class="white--text mt-5"
          color="#5d768e"
          href="https://altagenda.crossuite.com/osteos/o/74z2c47423v2o2543444h4w2a4v294g4d4x2x274c4s2x2y2u21353r264x2y2t203u22343r2d4v2s274x244d4s203o2b4c4d474z294v224 "
          target="new"
        >
          <v-icon class="mr-5">mdi-calendar</v-icon>
          Maak direct een afspraak
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center">
        <h2>Locaties</h2>
        <v-container>
          <v-row no-gutters class="text-left">
            <v-col
              v-for="(location, index) in locations"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <div class="location-card">
                <v-row no-gutters>
                  <v-col>
                    <div class="map-responsive">
                      <iframe
                        title="Google maps"
                        v-if="location.googleMaps"
                        v-bind:src="location.googleMaps"
                        width="400"
                        height="300"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                      ></iframe>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <address>
                    <h3>{{ location.city }}</h3>
                    {{ location.address }} <br v-if="location.extra" />
                    {{ location.extra }} <br />
                    {{ location.zipcode }} <br /><br v-if="!location.extra" />
                  </address>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    locations: [
      {
        city: "Berkel & Rodenrijs",
        address: "Nieuwstraat 13",
        extra: "",
        zipcode: "2651 CG",
        googleMaps:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22711.80532165967!2d4.482597487482252!3d51.98432985543106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5cbd1f92bd4a5%3A0x3cd5d31e1f6fd168!2sOsteo's!5e0!3m2!1sen!2snl!4v1625487884091!5m2!1sen!2snl"
      },
      {
        city: "Bleiswijk",
        address: "Christiaan Huygensstraat 14",
        extra: "",
        zipcode: "2665 KX",
        googleMaps:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2455.643884515805!2d4.540605416347706!3d52.013366781402226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5cc2c9eeaaaab%3A0xdfb5f7035f71f951!2sKlaas%20Osteopaat!5e0!3m2!1sen!2snl!4v1625488383796!5m2!1sen!2snl"
      },
      {
        city: "Rotterdam",
        address: "Voorburgstraat 14",
        extra: "",
        zipcode: "3037 PM",
        googleMaps:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.776194728537!2d4.465996116346325!3d51.93803568694511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5cb402722cf11%3A0x7179512c61829786!2sOsteopathie%20Daan%20de%20Bruijn!5e0!3m2!1sen!2snl!4v1625489751207!5m2!1sen!2snl"
      }
    ]
  })
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  // margin-bottom: 1rem;
  margin-top: 3rem;
}
address {
  font-style: normal;
  dl {
    dt,
    dd {
      display: inline-block;
      line-height: 2.2rem;
      a:link,
      a:hover,
      a:visited,
      a:active {
        text-decoration: none;
        color: #434343;
      }
      a:hover {
        text-decoration: underline;
        &.v-btn {
          text-decoration: none;
        }
      }
    }
    dt {
      margin-right: 10px;
    }
  }
}

h3 {
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
}

.location-card {
  background-color: #cacfb8;
  margin: 1rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

  address {
    padding: 1rem;
  }
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>
